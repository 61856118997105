<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div>
					<!--  导出 -->
					<el-button style="margin-left: 12px;" type="primary" @command="handleCommand" size="large"
						:hide-on-click="around" :loading="ConfirmLoading" :disabled='jinyong' @click="append">
						<img src="../../assets/daochu.png" style="width:14px;margin-right: 6px" alt="">
						导出
					</el-button>
				</div>
			</div>
			<!-- <div class="summation">
				<div class="hint">统计 : {{zong}}条</div>
			</div> -->
			<el-table :data="LLoginLogTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts">
				<!-- 会员姓名 -->
				<el-table-column align="center" label="会员姓名" prop="name" :show-overflow-tooltip='true' />
				<!-- 会员编号 -->
				<el-table-column label="会员编码" prop="username" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 所属工会 -->
				<el-table-column label="所属工会" prop="unionName" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 所属部门 -->
				<el-table-column label="所属部门" prop="departmentName" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 打分值 -->
				<el-table-column label="打分值" prop="fractionValue" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 打分时间 -->
				<el-table-column label="打分时间" prop="createTime" align="center" :formatter="reach" width="180"
					:show-overflow-tooltip='true'>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<!-- 一页时候隐藏 hide-on-single-page="true" -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
				:current-page="currentPage" :page-size="pagesize" :total="zong" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" />
		</el-card>

		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
		<!-- 导出提示 -->
		<div>
			<s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
				<div class="task">
					{{quoth}}
				</div>
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import {
		Search,
		Setting,
		WalletFilled,
		Van,
		ShoppingCartFull,
		Download
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		ref,
		onMounted,
		onDeactivated,
		onBeforeUnmount,
		onActivated,
	} from 'vue'
	// import { HFlist,Zzzlist,HFmonthly,HFyear } from '../utils/api'
	import {
		ZClist,
		inScoring,
		outScoring,
	} from '../../utils/api'
	// import moments from 'moment'
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	import qs from 'qs';
	import moments from 'moment'
	// import moments from 'moment'
	export default {
		name: "dfdetail",
		computed: {},
		setup() {
			//时间格式转换
			let reach = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
				}
			}
			// 下载提示弹窗
			let anew = ref(false)

			let anew1 = ref(false)
			// 搜索加载
			const formative = ref(false)
			// 导出接口成功字段
			let quoth = ref('')
			// 导出按钮是否禁用
			let jinyong = ref(false)
			// 导出加载
			let ConfirmLoading = ref(false)
			// 加载中
			let texts = ref('加载中')
			//隐藏下拉框
			let selectIt = ref()
			// 下拉隐藏
			let closeIt = () => {
				selectIt.value.blur();
			}
			// 导出按钮
			let append = () => {
				jinyong.value = true
				ConfirmLoading.value = true
				let data = {
				programId: sessionStorage.getItem('programId'),
				}
				console.log(data)
				outScoring(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						anew.value = true
						quoth.value = '任务已提交，请在右侧查看进度'
						jinyong.value = false
						ConfirmLoading.value = false
						// window.open(res.data.data.excelUrl)
					} else {
						// anew.value = false
						jinyong.value = false
						ConfirmLoading.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}

			// 下拉按钮选择
			const handleCommand = (command) => {
				console.log(command)
				if (command == 'personage') {
					payout()
				} else if (command == 'personage1') {
					detail()
				} else if (command == 'personage2') {
					passage()
				} else if (command == 'personage3') {
					barter()
				}
			}
			// 下拉按钮动态收回
			// const around = ref(false)
			// // 加载中
			const acting = ref(false)
			// // 按钮禁用
			// const jinyong = ref(false)
			// 工会清空
			const numerical = (e) => {
				if (e == '') {
					Hcode.value = ''
				}
			}
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					loading.value = true
					list()
				}
			})
			// 获取任务进度列表
			const schedule = ref([])
			// 获取任务进度状态
			const condition = ref(null)

			// 获取任务进度
			const missions = () => {
				RWall().then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						schedule.value = res.data.data.systemTasksList
						condition.value = schedule.value[0].state
					} else {
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}

			// 下载模板
			const download = () => {
				FYdownload().then((res) => {
					console.log(res)
					window.open(res.data.data.excelExampleUrl)
				})
			}

			// 数据总共条数
			let zong = ref(null)
			// 列表数据循环
			const LLoginLogTableData = ref([])
			//获取列表页传来的id
			const route = useRoute()
			let loading = ref(true)
			let spChildIndex = ref(0)
			// 表格数据接口
			let list = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value,
					programId: sessionStorage.getItem('programId'),
				}
				inScoring(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						// zong.value = res.data.data.activityRegistrationList.totalCount
						LLoginLogTableData.value = res.data.data.fractionList
						if (LLoginLogTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 分页获取积分的派发日志
			// 动态加载数据
			let branches = ref(6)
			// 分页
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				console.log(size)
				pagesize.value = size
				branches.value = size
				if (designation.value !== '' || serial.value !== '' || ability.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				console.log(size)
				currentPage.value = size
				texts.value = '加载中'
				if (designation.value !== '' || serial.value !== '' || ability.value !== '') {
					search()
				} else {
					list()
				}
			}


			let search1 = () => {
				formative.value = true
				search()
			}
			// 搜索框点击
			let search = () => {
				// 时间截止
				// 工会code
				// console.log(Hcode.value)
				// 会员编码框值
				// console.log(serial.value)
				// 姓名
				// console.log(designation.value)
				// console.log('开始', a.value)
				// console.log('结束', out.value)
				// gration()
				// 搜索接口
				let data = {
					limit: branches.value,
					page: currentPage.value,
					//活动id
					programId: sessionStorage.getItem('programId'),
				}
				inScoring(qs.stringify(data)).then((res) => {
					// console.log(res)
					if (res.data.code == 0) {
						formative.value = false
						// zong.value = res.data.data.activityRegistrationList.totalCount
						LLoginLogTableData.value = res.data.data.fractionList
						if (LLoginLogTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}

			let a = ref('')
			let b = ref('')
			let c = ref('')
			let out = ref('')


			// 时间清空监听
			const immediUpdate = (e) => {
				if (e !== null) {
					a.value = new Date(e[0]).getTime() / 1000
					b.value = new Date(e[1]).getTime() / 1000
					c.value = moments(b.value * 1000).format().substring(0, 10)
					out.value = new Date(c.value + ' ' + '23:59:59').getTime() / 1000
				} else if (e == null) {
					a.value = ''
					b.value = ''
					c.value = ''
					out.value = ''
				}
				console.log(e)
			}

			// 搜索组织列表接口
			// 获取code 
			let Hcode = ref('')
			// 搜索组织选择值
			let ability = ref('')
			// 弹窗树形选择
			let dendrogram = (node) => {
				ability.value = node.unionName
				Hcode.value = node.unionCode
				console.log(node)
			}
			const lang = () => {
				ZClist().then((res) => {
					// console.log(res)
					texture.value = res.data.data.manageUnionList
				})
			}
			// 弹窗组织列表树形
			let texture = ref([])
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			// 选择组织按钮
			let multiple = () => {
				visible.value = true
			}
			// 搜索组织弹窗
			let visible = ref(false)



			// 会员编码框
			let serial = ref('')
			// 搜索框变化值
			let orangutan = () => {
				// if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
				//   list()
				//   console.log('空了')
				// }
				console.log('变化了')
			}
			// 姓名输入框值
			let designation = ref('')
			// 编辑跳转
			const copyreader = (id) => {
				console.log(id)
				sessionStorage.setItem('HFrecord', id)
				router.push({
					name: 'HFrecord',
					params: {
						UserId: 1
					}
				});
			}

			// 时间选择
			let value1 = ref('')
			const whirl = ref(false)

			onDeactivated(() => {
				// window.clearInterval(timer.value);
				// console.log('离开了')
			})

			onBeforeUnmount(() => {
				// window.clearInterval(timer.value);
				// console.log('离开了123')
			})


			// vuex获取状态
			const store = useStore();
			// 获取当前页path
			const present = ref('')
			// 通过vuex获取标签栏
			const tally = ref([])

			onMounted(() => {
				// 获取标签栏数组
				tally.value = store.state.tagsList
				// 获取当前页path
				present.value = router.path

				// console.log(route.path)
				// console.log(store.state.tagsList)

				// gration()
				lang()
				list()
			})
			// 待办内容点击事件
			const router = useRouter()
			const backlog = (row) => {
				router.push({
					path: '/homes'
				})
				console.log(row.date)
			}
			// 待办内容
			const tableData = [];
			// 头像加载失败
			const errorHandler = () => true;
			return {

				// copyreader,
				// condition,
				// own,
				// quoth1,
				quoth,
				// subscribe,
				// table,
				// 浮窗点击
				// ocean,
				// 预约下载中心弹窗
				// centre,
				// 下载提示弹窗
				anew1,
				anew,
				// 过期按钮
				search1,
				// 搜索加载
				formative,
				// 下拉按钮选择
				// wrought,
				// handleCommand,
				// around,
				// pleased,
				// acting,
				// uploadHttpRequest,
				// error,
				jinyong,
				numerical,
				// 下载模板
				download,

				immediUpdate,
				reach,
				LLoginLogTableData,

				// 分页
				zong,
				branches,
				handleCurrentChange,
				handleSizeChange,
				currentPage,
				pagesize,
				// 组织弹窗
				ability,
				texture,
				visible,
				dendrogram,
				defaultProps,
				multiple,
				// 会员编码框
				serial,
				// 搜索框
				search,
				// 姓名框变化值
				orangutan,
				// 会员姓名
				designation,
				// 时间选择
				whirl,
				value1,
				errorHandler,
				tableData,
				backlog,
				texts,
				append,
				closeIt,
				selectIt,
			};
		},
		components: {
			Search,
			Download,
			WalletFilled,
			Van,
			ShoppingCartFull,
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .el-form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

	}

	.setting {
		display: flex;
	}

	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	.summation {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 10px;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 150px);
		overflow: auto;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 22%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: flex-end;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
</style>
<style>
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>